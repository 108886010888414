<template>
  <div>
    <div class="tableContainer">
      <el-table stripe :data="list" height="calc(100vh - 380px)">
        <el-table-column align="center" prop="id" label="订单号"> </el-table-column>
        <el-table-column align="center" label="商品信息">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.skuPic | thumbMedium" />
              <el-tooltip effect="dark" placement="top" :content="scope.row.skuName">
                <span class="name"> {{ scope.row.skuName | sliceString(8) }}...</span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="memberName" label="会员昵称"> </el-table-column>

        <el-table-column align="center" prop="skuTotalNum" label="购买数量"> </el-table-column>
        <el-table-column align="center" prop="skuPayMoney" label="支付金额"> </el-table-column>
        <el-table-column align="center" prop="status" label="订单状态">
          <template slot-scope="scope"> {{ scope.row.orderShowStatus | formatOrderStatus }}</template>
        </el-table-column>
        <el-table-column align="center" prop="orderTime" label="订单创建时间">
          <template slot-scope="scope"> {{ scope.row.orderTime | formatTime }}</template>
        </el-table-column>
        <el-table-column align="center" prop="notes" label="订单备注">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top" :content="scope.row.notes">
              <span> {{ scope.row.notes | sliceString(6) }}...</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small"> 查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      stateOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '待支付'
        },
        {
          value: '1',
          label: '待核销'
        },
        {
          value: '2',
          label: '已核销'
        },
        {
          value: '3',
          label: '已过期'
        },
        {
          value: '4',
          label: '已关闭'
        }
      ],
      list: [],
      orderTime: [],
      storeList: [],
      currentPage: 1,
      searchValue: {
        id: '',
        memberType: 'memberId',
        memberId: '',
        memberName: '',
        orderStatus: '',
        orderTimeEnd: '',
        orderTimeStart: ''
      }
    }
  },

  methods: {
    // 搜索
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          memberId: this.$route.query.id,
          buyTypeList: '0,1,2,3',
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$api.order
        .order('orderList', params)
        .then(res => {
          let { list, total } = res
          this.list = list
          this.total = total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })

        .finally(() => {
          this.loading = false
        })
    },

    // 查看订单详情
    check(row) {
      this.$router.push({
        path: '/order/orderParticulars',
        query: {
          id: row.id,
          memberId: row.memberId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product-info {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }

  .name {
    vertical-align: middle;
  }
}

.query {
  margin-top: 20px;
}
</style>
