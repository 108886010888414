<template>
  <div>
    <div class="tableContainer">
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="memberNickname" label="用户昵称"> </el-table-column>
        <el-table-column align="center" label="商品信息">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.skuPhoto | thumbMedium" v-if="scope.row.skuPhoto" />
            <span v-else>--</span>
            <div class="info">{{ scope.row.skuTitle }}</div>
          </template>
        </el-table-column>

        <el-table-column label="评价信息" align="center">
          <template slot-scope="scope">
            <img
              class="avatar"
              v-for="(item, index) in scope.row.reviewPhoto.filter((v, i) => i < 3)"
              :key="index"
              :src="item | thumbMedium"
            />
            <div class="commentInfo">{{ scope.row.content }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="评分">
          <template slot-scope="scope">
            <el-rate v-model="scope.row.allStar" disabled></el-rate>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope"> {{ scope.row.createTime | formatTime }}</template>
        </el-table-column>

        <el-table-column align="center" prop="" label="删除状态">
          <template slot-scope="scope">
            {{ scope.row.showStatus === 0 ? '已删除' : '正常展示' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :total="total"
          layout="total, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      time: [],
      searchValue: {
        memberNickname: '',
        skuTitle: '',
        allStar: '',
        endTime: '',
        startTime: ''
      },
      list: [],
      starOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '一星'
        },
        {
          value: 2,
          label: '二星'
        },
        {
          value: 3,
          label: '三星'
        },
        {
          value: 4,
          label: '四星'
        },
        {
          value: 5,
          label: '五星'
        }
      ]
    }
  },

  methods: {
    // 查看详情
    check(row) {
      this.$router.push({
        path: '/product/comment/particulars',
        query: {
          id: row.id
        }
      })
    },

    // 搜索评论
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$api.user
        .user('userReview', params, this.$route.query.id)
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.commentInfo {
  vertical-align: middle;
}
.query {
  margin-top: 20px;
}
</style>
