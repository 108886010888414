<route>
{
  "meta": {
    "auth": "userDetail"
  }
}
</route>

<template>
  <div>
    <el-card class="userInfoContainer">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="8">
          <div class="basis">
            <img class="icon" :src="userDetail.avatarUrl | thumbSmall" />
            <span>姓名： {{ userDetail.wxNickname }}</span>
            <span> ID：{{ userDetail.id }}</span>
            <div style="margin: 10px">
              <el-button type="info" @click="handleCode">生成获取验证码</el-button>
            </div>

            <div>
              <el-button type="primary" @click="changeUserPhone">强制修改手机号</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="userDetail">
            <div>
              <span class="intro">性别：</span>
              <span>{{ userDetail.gender | gender }}</span>
            </div>
            <div>
              <span class="intro">电话：</span>
              <span>{{ userDetail.purePhoneNumber ? userDetail.purePhoneNumber : '暂未授权' }}</span>
            </div>
            <div>
              <span class="intro">地址：</span>
              <span>{{ userDetail.area ? userDetail.area : '暂未授权地理信息' }}</span>
            </div>

            <div>
              <span class="intro">是否使用</span>
              <span>{{ userDetail.isUse ? '已使用' : '未使用' }}</span>
            </div>

            <div>
              <span class="intro">授权地区</span>

              <span v-if="userDetail.authorizedArea == 0">无</span>
              <span v-else>{{ userDetail.authorizedArea | formatArea }}</span>
            </div>

            <div>
              <span class="intro">活跃地区</span>
              <span v-if="userDetail.activeArea == 0">无</span>
              <span v-else>{{ userDetail.activeArea | formatArea }}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="userDetail">
            <div>
              <span class="intro">上次登录时间:</span>
              <span>
                {{ userDetail.lastLogin | formatTime }}
              </span>
            </div>
            <div>
              <span class="intro">是否购买：</span>
              <span> {{ userDetail.isBuy ? '已购买' : '未购买' }}</span>
            </div>
            <div>
              <span class="intro">状态：</span>
              <span> {{ userDetail.isBlacklisted ? '被拉黑' : '正常' }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog :close-on-click-modal="false" center :visible.sync="getCode.show" title="验证码" width="250px">
      <div style="text-align: center; font-size: 20px; font-weight: bold">{{ getCode.code }}</div>
      <div style="text-align: center; margin-top: 20px">手机号: {{ userDetail.purePhoneNumber }}</div>
      <div slot="footer"><el-button @click="getCode.show = false">关闭</el-button></div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" center :visible.sync="changePhone.show" title="修改手机号" width="250px">
      <el-form label-width="100px">
        <el-form-item label="区号">
          <el-input v-model="changePhone.countryCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="changePhone.purePhoneNumber" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="changePhone.show = false">关闭</el-button
        ><el-button type="success" @click="confirmChangeUserPhone">确定</el-button>
      </div>
    </el-dialog>
    <el-tabs class="main" type="border-card">
      <el-tab-pane label="订单信息"><com-Order></com-Order></el-tab-pane>
      <el-tab-pane label="评价信息"><com-Comment></com-Comment></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import comOrder from './__com__/order'
import comComment from './__com__/comment'
export default {
  components: {
    comOrder,
    comComment
  },
  data() {
    return {
      component: 'comOrder',
      userDetail: {},
      getCode: {
        code: '',
        show: false
      },
      changePhone: {
        show: false,
        countryCode: '+86',
        purePhoneNumber: ''
      }
    }
  },
  created() {
    this.getDetail()
  },

  methods: {
    changeUserPhone() {
      this.changePhone.show = true
    },
    handleCode() {
      this.$http
        .get('boom-center-user-service/sysAdmin/members/generateVerifyCodeToGetSecurityCode/' + this.userDetail.id)
        .then(res => {
          this.getCode.code = res
          this.getCode.show = true
        })
    },
    confirmChangeUserPhone() {
      this.$http
        .put('boom-center-user-service/sysAdmin/members/modifyPhoneNumber/' + this.userDetail.id, {
          countryCode: this.changePhone.countryCode,
          purePhoneNumber: this.changePhone.purePhoneNumber
        })
        .then(res => {
          this.$message.success('修改成功')
          this.changePhone.show = false
        })
        .catch(err => {
          this.$message.success(err.msg)
        })
    },
    getDetail() {
      this.$api.user
        .user('userDetail', this.$route.query.id)
        .then(res => {
          this.userDetail = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    switchTab(v) {
      if (v === 'o') {
        this.component = 'comOrder'
      } else {
        this.component = 'comComment'
      }
    }
  },
  filters: {
    gender(value) {
      switch (value) {
        case 1:
          return '男'
          break
        case 2:
          return '女'
          break

        default:
          return '保密'
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.userInfoContainer {
  margin: @container-margin;
  padding: 20px;

  .basis {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 55px;
      height: 55px;
    }
  }
  .userDetail {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    div {
      line-height: 30px;
      .intro {
        display: inline-block;
        width: 100px;
        color: #999;
      }
    }
  }
}

.main {
  margin: @container-margin;
}
</style>
